import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// image tiles
import nbnDcHero from '../img/nbn/nbn-welcome.jpg'
import nbnDcHeroLQ from '../img/nbn/nbn-welcome-lq.jpg'

import nbnMap from '../img/nbn/nbn-map.gif'
import nbnHome from '../img/nbn/nbn-home.jpg'
import nbnHomeLQ from '../img/nbn/nbn-home-lq.jpg'
import nbnConnection from '../img/nbn/connection-wall.jpg'
import nbnConnectionLQ from '../img/nbn/connection-wall-lq.jpg'
import nbnAusmap from '../img/nbn/nbn-ausmap.jpg'
import nbnAusmapLQ from '../img/nbn/nbn-ausma-lq.jpg'
import nbnBusroom from '../img/nbn/nbn-busroom.jpg'
import nbnBusroomLQ from '../img/nbn/nbn-busroo-lq.jpg'
import nbnCorridor from '../img/nbn/nbn-corridor.jpg'
import nbnCorridorLQ from '../img/nbn/nbn-corridor-lq.jpg'


import energyOdysseyTile from '../img/synergy/energy-odyssey/energy-odyssey-tile.jpg'
import energyOdysseyTileLQ from '../img/synergy/energy-odyssey/energy-odyssey-tile-lq.jpg'

export default function NbnDc() {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const pageTransition = {
        initial: {opacity: 0, x: 50},
        animate: {opacity: 1, x: 0},
        exit: {opacity: 0, x: 50}
    }

    return (<>
        <motion.div key="portfolio_page" className='min-h-screen flex flex-col lg:px-0 pt-20' variants={pageTransition} initial="initial" animate="animate" exit="exit">
            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-16 lg:space-y-0 '>
                    <h1 className='pb-0'>Discovery Centre</h1>
                    <h3 className='pb-16 font-light'>NBN Australia</h3>
                <div className='w-full lg:w-4/6 2xl:w-2/4 lg:mt-[10vh] mx-auto'>
                    <div id="spaceescape" className='text-center lg:text-left pb-16 px-4'>
                        
                        {/* Hero Image */}
                        <div className='grid grid-cols-2 lg:grid-cols-3 gap-3 mb-8 lg:mb-16'>
                            <div className='col-span-full'><LazyLoadImage src={nbnDcHero} placeholderSrc={nbnDcHeroLQ} className='w-full scale-[1.15] lg:scale-100' alt="" /></div>
                            <div className='hidden lg:block lg:col-span-1'><LazyLoadImage src={nbnAusmap} placeholderSrc={nbnAusmapLQ} className='w-full ' alt="" /></div>
                            <div className='hidden lg:block lg:col-span-1'><LazyLoadImage src={nbnBusroom} placeholderSrc={nbnBusroomLQ} className='w-full ' alt="" /></div>
                            <div className='hidden lg:block lg:col-span-1'><LazyLoadImage src={nbnCorridor} placeholderSrc={nbnCorridorLQ} className='w-full ' alt="" /></div>
                        </div>

                        <aside className='text-center lg:text-left w-full mx-auto mb-16 2xl:w-3/4'>
                                <p className='text-mobile leading-5 lg:leading-5 md:text-average mt-6 font-semibold'>Key responsibilities:</p>
                                <ul className='text-mobile leading-5 lg:leading-5 list-outside ml-6 mt-3 font-light grid grid-cols-1 lg:grid-cols-3 gap-1.5 lg:gap-6'>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🧬</span> Concept development &amp; ideation in collaboration with strategists and writers</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🔮</span> Research and propose technical solutions to provide moments of “magic” for visitors</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🚀</span> Overseeing hardware installation and testing</li>
                                </ul>
                        </aside>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large font-semibold'>
                            Perched atop NBN's new home in North Sydney, the NBN Discovery Centre is a remarkable venue for storytelling. With various zones accommodating diverse visitors, the Discovery Centre artfully integrates technology into its physical environment.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            As the project's creative technologist, I shaped our technology strategy, prioritising solutions that enabled people to share stories, experiences, and network without with technology as a means to enhance these moments, rather than distract.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            <LazyLoadImage src={nbnMap} className='w-full lg:w-2/3 2xl:w-2/4 mx-auto mb-10 pt-16' alt="" />
                            Upon entry, visitors were welcomed by a map of Australia dotted with small portholes, each featuring a miniature screen. These screens spotlighted individuals and communities pivotal in the nationwide rollout of the NBN network.
                        </p>
                        
                    </div>
                </div>
            </div>

            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>

                <div className='grid grid-cols-10 gap-6 w-full lg:w-4/6 2xl:w-2/4 mx-auto text-center lg:text-left items-center '>
                    <div className='col-span-full lg:col-span-6'>
                        <LazyLoadImage src={nbnHome} placeholderSrc={nbnHomeLQ} className='w-full h-full object-cover' alt="artwork collection" />
                    </div>
                    <p className='col-span-full lg:col-span-4 para text-mobile leading-6 lg:leading-7 lg:text-large'>
                        The journey continued into a home-like setting, a comfortable meeting space subtly embedded with technology. IoT devices provided the host with complete control over the environment, digital frames displayed curated artworks personalised for guests, and screens sprang to life as needed to present pre-loaded content.
                    </p>

                </div>

            </div>

            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>

                <div className='w-full lg:w-4/6 2xl:w-2/4 mx-auto text-center lg:text-left'>
                    <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large  pt-16'>
                        The main hub was home to a wall-to-wall LED screen, speakers and microphone arrays discreetly installed in the ceiling. This setup offered a versatile event space with conferencing capabilities, connecting remote hubs and fostering a sense of shared presence.
                    </p>
                    <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large pb-10'>
                        To highlight the physical infrastructure behind the NBN, I built an interactive wall that utilised conductive ink to create a sense of magic. This innovative solution allowed us to paint touch-activated zones behind the wall, rendering them invisible. Visitors could touch areas of the wall to animate the space, with projection-mapped content augmenting the printed graphics.
                    </p>
                    
                </div>

                <div className='w-full lg:w-4/6 2xl:w-2/4 mx-auto text-center lg:text-left items-center '>
                        <LazyLoadImage src={nbnConnection} placeholderSrc={nbnConnectionLQ} className='w-full h-full object-cover' alt="artwork collection" />
                </div>

                <div className='w-full lg:w-4/6 2xl:w-2/4 mx-auto text-center lg:text-left'>
                    <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large pt-24'>
                        The NBN Discovery Centre consisted of six zones, each working in harmony to weave a variety of narratives or function independently, creating a multifaceted, versatile destination. This immersive experience demonstrated to visitors how enhanced connectivity and technology can empower all Australians.
                    </p>
                </div>

                <div className='w-full lg:w-4/6 2xl:w-2/4 mx-auto text-center lg:text-left'>
                    {/* <LazyLoadImage src={escapeRender} placeholderSrc={escapeRenderLQ} className='w-full mt-16' alt="artwork collection" /> */}
                </div>
            </div>

            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>
                <motion.div initial={{opacity: 0, y: 30}} whileInView={{opacity: 1, y: 0, transition: {delay: 0.5}}} viewport={{once: true, margin: "-100px 0px 0px 0px"}} key="upnext"
                className='w-3/4 lg:w-1/4 2xl:w-1/6 mx-auto text-center snap-center hover:cursor-pointer group flex flex-col lg:place-content-between py-20'>
                    <Link to="/portfolio" title="back to portfolio">
                        <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent ml-auto lg:ml-0 mr-auto' title="back to portfolio">
                            Back to portfolio
                        </div>
                    </Link>
                </motion.div>
            </div>

        </motion.div >
    </>);
}