import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';


// image tiles
import generatorTile from '../img/alpha_academy/ae-generator-tile.jpg'
import generatorTileLQ from '../img/alpha_academy/ae-generator-tile-lq.jpg'
import alphaWebsiteTile from '../img/alpha_academy/aa-website-tile.jpg'
import alphaWebsiteTileLQ from '../img/alpha_academy/aa-website-tile-lq.jpg'
import smartContract from '../img/alpha_academy/ae-smartcontract-tile.jpg'
import smartContractLQ from '../img/alpha_academy/ae-smartcontract-tile-lq.jpg'

import escapeRoomHero from '../img/synergy/escape-room/escape-room-lander.jpg'
import escapeRoomHeroLQ from '../img/synergy/escape-room/escape-room-lander-lq.jpg'
import energyOdysseyTile from '../img/synergy/energy-odyssey/energy-odyssey-tile.jpg'
import energyOdysseyTileLQ from '../img/synergy/energy-odyssey/energy-odyssey-tile-lq.jpg'
import vppTile from '../img/synergy/vpp/vpp-tile.jpg'
import vppTileLQ from '../img/synergy/vpp/vpp-tile-lq.jpg'

import atlassian from '../img/atlassian/att-20-tile.png'
import atlassianLQ from '../img/atlassian/att-20-tile-lq.jpg'

import nbndc from '../img/nbn/nbn-tile.jpg'
import nbndcLQ from '../img/nbn/nbn-tile-lq.jpg'

export default function Portfolio() {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const animations = {
        initial: {opacity: 0},
        animate: {opacity: 1},
        exit: {opacity: 0, y: 50}
    }


    return (<>
        <motion.div key="portfolio_page" className='min-h-screen flex lg:px-0 pt-20' variants={animations} initial="initial" animate="animate" exit="exit">
            <div className='flex flex-col md:flex-row w-full text-center px-0 lg:px-8 bg-almostBlack pt-16 space-y-16 lg:space-y-0'>
                <aside className='my-auto text-center lg:text-left w-full lg:w-1/4 lg:pr-8 relative h-full'>
                    <div className='relative pr-0 lg:fixed lg:w-1/4 lg:pr-8'>
                        <h1 className='pb-0'>Portfolio</h1>
                        <p className='text-mobile md:text-average'>Click on a tile to learn more.</p>
                    </div>
                </aside>

                <div className='w-full lg:w-3/4 2xl:w-2/4 lg:mt-[10vh]'>

                    {/* Alpha Academy */}
                    <div id="alphaacademy" className='text-center lg:text-left col-span-3 pb-16 px-4'>
                        <h1>Alpha Academy</h1>
                        <p className='para'>The Alpha Academy is a collaborative exploration of art and technology. The project is a joint work between myself and Sam - a Sydney based illustrator. Together we've launched a project that focuses on quality and playful exploration of established and emerging technologies.</p>
                        <p>The Alpha Academy project has spanned over a year of my time and encompasses a myriad of elements. A selection of these have been presented below. Follow the links to read a more in-depth explanation of each element.</p>
                    </div>

                    <div className="w-full flex overflow-x-auto scrolling-touch snap-x snap-mandatory space-x-6 lg:space-x-4 pr-8 pb-32">

                            <motion.div key="artwork-gen" className='w-11/12 flex-shrink-0 lg:flex-shrink md:w-1/3 text-center lg:text-left snap-center hover:cursor-pointer group ml-4 flex flex-col lg:place-content-between'>
                                <Link to="/artwork-generator" title="View the project">
                                    <div className='flex flex-col'>
                                        <LazyLoadImage src={generatorTile} placeholderSrc={generatorTileLQ} className='w-full' alt="artwork collection" />
                                        <h3 className='mt-10 mb-4'>Artwork Generator</h3>
                                        <p className='text-mobile'>
                                            The heart of our project. The artwork generator was an entire site built for a single user: the artist. It allowed us to manage assets, layering, create rules for how assets would function with each other as well as preview and generate the collection.
                                        </p>
                                    </div>
                                    <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent ml-auto lg:ml-0 mr-auto' title="View the project">
                                        View Project
                                    </div>
                                </Link>
                            </motion.div>

                            <motion.div key="alpha-website" className='w-11/12 flex-shrink-0 lg:flex-shrink md:w-1/3 text-center lg:text-left snap-center hover:cursor-pointer group flex flex-col lg:place-content-between'>
                                <Link to="/alpha-website" title="View the project">
                                    <div className='flex flex-col'>
                                    <LazyLoadImage src={alphaWebsiteTile} placeholderSrc={alphaWebsiteTileLQ} className='w-full' alt="artwork collection" />
                                        <h3 className='mt-10 mb-4'>Alpha Academy Website</h3>
                                        <p className='text-mobile'>
                                        Our product site introduced visitors to the Alpha Academy universe, allowed them to buy out artworks, our limited edition drops (via custom ecomm store) as well as providing additional member-only benefits, accessible via Web3 integration.
                                        </p>
                                    </div>
                                    <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent ml-auto lg:ml-0 mr-auto' title="View the project">
                                        View Project
                                    </div>
                                </Link>
                            </motion.div>

                            <motion.div key="smart-contracts" className='w-11/12 flex-shrink-0 lg:flex-shrink md:w-1/3 text-center lg:text-left snap-center hover:cursor-pointer group mr-8 flex flex-col lg:place-content-between'>
                                <Link to="/smart-contracts" title="View the project">
                                    <div className='flex flex-col'>
                                        <LazyLoadImage src={smartContract} placeholderSrc={smartContractLQ} className='w-full' alt="artwork collection" />
                                        <h3 className='mt-10 mb-4'>Smart Contracts</h3>
                                        <p className='text-mobile'>
                                            ERC-721 smart contracts deployed on the Ethereum network to facilitate trustless, decentralised acquisition, transfer and querying of our art collection.
                                        </p>
                                    </div>
                                    <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent bottom-0 ml-auto lg:ml-0 mr-auto' title="View the project">
                                        View Project
                                    </div>
                                </Link>
                            </motion.div>

                    </div>


                    {/* Synergy Energy */}
                    <div id="synergy" className='text-center lg:text-left col-span-3 pb-16 px-4'>
                        <h1>Synergy Energy</h1>
                        <p className='para'>Synergy Energy is Western Australia's primary retail energy provider. Working with their team, I worked across a range of projects aimed at boosting energy literacy. By empowering customers to take charge of their energy usage, we were able to positively impact daily habits. The featured projects below, which I led both creatively and technically, demonstrate unique activations tailored to various demographics using diverse technologies.</p>
                    </div>

                    <div className="w-full flex overflow-x-auto scrolling-touch snap-x snap-mandatory space-x-6 lg:space-x-4 pr-8 pb-32">

                            <motion.div key="spaceescape" className='w-11/12 flex-shrink-0 lg:flex-shrink md:w-1/3 text-center lg:text-left snap-center hover:cursor-pointer group ml-4 flex flex-col lg:place-content-between'>
                                <Link to="/space-escape" title="View the project">
                                    <div className='flex flex-col'>
                                        <LazyLoadImage src={escapeRoomHero} placeholderSrc={escapeRoomHeroLQ} className='w-full' alt="space escape room" />
                                        <h3 className='mt-10 mb-4'>Synergy Space Escape</h3>
                                        <p className='text-mobile'>
                                            The mission: educate children age 8 - 10 on the basics of how energy works. The challenge: make it fun. The solution: a mobile escape room that turned them into explorers and launched them into the stars to working together in order setup an advanced, energy efficient colony on a new world.
                                        </p>
                                    </div>
                                    <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent ml-auto lg:ml-0 mr-auto' title="View the project">
                                        View Project
                                    </div>
                                </Link>
                            </motion.div>

                            <motion.div key="energyodyssey" className='w-11/12 flex-shrink-0 lg:flex-shrink md:w-1/3 text-center lg:text-left snap-center hover:cursor-pointer group flex flex-col lg:place-content-between'>
                                <Link to="/energy-odyssey" title="View the project">
                                    <div className='flex flex-col'>
                                    <LazyLoadImage src={energyOdysseyTile} placeholderSrc={energyOdysseyTileLQ} className='w-full' alt="energy odyssey" />
                                        <h3 className='mt-10 mb-4'>Energy Odyssey</h3>
                                        <p className='text-mobile'>
                                            As part of the wider 'Power Up' campaign targeting customers struggling with energy bills, we launched Energy Odyssey. This side-scrolling game put players in control of Renny the Quokka, on its mission to save Western Australia from energy-hungry appliances. Using gamification, we reinforced positive energy habits within the at-risk community.
                                        </p>
                                    </div>
                                    <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent ml-auto lg:ml-0 mr-auto' title="View the project">
                                        View Project
                                    </div>
                                </Link>
                            </motion.div>

                            <motion.div key="vpps" className='w-11/12 flex-shrink-0 lg:flex-shrink md:w-1/3 text-center lg:text-left snap-center hover:cursor-pointer group mr-8 flex flex-col lg:place-content-between'>
                                <Link to="/vpp-ar" title="Virtual Power Plants AR">
                                    <div className='flex flex-col'>
                                        <LazyLoadImage src={vppTile} placeholderSrc={vppTileLQ} className='w-full' alt="artwork collection" />
                                        <h3 className='mt-10 mb-4'>Synergy Virtual Power Plants (VPP) AR Experience</h3>
                                        <p className='text-mobile'>
                                            An augmented reality tool that armed SMEs with an immersive toolkit. This innovative tool empowered them to better demonstrate Synergy Energy's groundbreaking approach to decentralised power sharing.
                                        </p>
                                    </div>
                                    <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent bottom-0 ml-auto lg:ml-0 mr-auto' title="View the project">
                                        View Project
                                    </div>
                                </Link>
                            </motion.div>

                    </div>

                    {/* Atlassian */}
                    <div id="atlassian" className='text-center lg:text-left col-span-3 pb-32 px-4'>
                        <h1>Atlassian: <br className='block lg:hidden' /> Through The Years</h1>
                            <motion.div key="tty" className='w-full text-center lg:text-left snap-center hover:cursor-pointer group flex flex-col lg:flex-row space-x-0 lg:space-x-4 space-y-10 lg:space-y-0 place-items-center'>
                                <Link to="/atlassian" title="View the project" className='w-full lg:w-1/3 pr-2'>
                                    <LazyLoadImage src={atlassian} placeholderSrc={atlassianLQ} className='w-full' alt="space escape room" />
                                </Link>
                                <Link to="/atlassian" title="View the project" className='w-full lg:w-2/3'>
                                    <div className='flex flex-col'>
                                        <p className='text-mobile'>
                                            'Atlassian: Through The Years' commemorated Atlassian's 20th anniversary, serving as a vibrant reflection of their rich history, culture, and values. An immersive web3D journey, this interactive experience allowed staff members to hear founding voices, uncover hidden artefacts, and collect virtual mementos, all while exploring the company's most pivotal moments across time and space.
                                        </p>
                                        <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent ml-auto lg:ml-0 mr-auto' title="View the project">
                                            View Project
                                        </div>
                                    </div>
                                </Link>
                            </motion.div>
                    </div>


                    {/* NBN DC */}
                    <div id="nbndc" className='text-center lg:text-left col-span-3 pb-16 px-4'>
                        <h1>NBN Discovery Centre</h1>
                            <motion.div key="tty" className='w-full text-center lg:text-left snap-center hover:cursor-pointer group flex flex-col lg:flex-row space-x-0 lg:space-x-4 space-y-10 lg:space-y-0 place-items-center'>
                                <Link to="/nbndc" title="View the project" className='w-full lg:w-1/3 pr-2'>
                                    <LazyLoadImage src={nbndc} placeholderSrc={nbndcLQ} className='w-full' alt="nbn discovery center" />
                                </Link>
                                <Link to="/nbndc" title="View the project" className='w-full lg:w-2/3'>
                                    <div className='flex flex-col'>
                                        <p className='text-mobile'>
                                            The NBN Discovery Centre was a physical space to bring in enterprise clients, small businesses and community groups and showcase how NBN broadband is helping shape the future of Australia. Built around the idea of NBN as the fabric of Australia, it leveraged an array of technologies to bring various stories to life
                                        </p>
                                        <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent ml-auto lg:ml-0 mr-auto' title="View the project">
                                            View Project
                                        </div>
                                    </div>
                                </Link>
                            </motion.div>
                    </div>

                


                </div>

            </div>
        </motion.div >
    </>);
}

{/* <div className='flex flex-col place-content-center w-screen text-center mx-auto'>
    <h1>This is my H1 Alpha Academy<br /> and here it is as a double line.</h1>
    <h2>This is my H2 <br /> and here it is as a double line.</h2>
    <h3>This is my H3 <br /> and here it is as a double line.</h3>
    <h4>This is my H4 <br /> and here it is as a double line.</h4>
    <p className="large">This is my intro P tag</p>
    <p>This is my standard P tag</p>
    <button className=''>Primary Button</button>
    <button className='btn-secondary'>Secondary Button</button>
</div> */}