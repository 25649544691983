import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import VideoComponent from './videoPlayer';

// image tiles
import ethereumBanner from '../img/alpha_academy/ethereum-banner.jpg'
import ethereumBannerLQ from '../img/alpha_academy/ethereum-banner-lq.jpg'

import AeContract from '../img/alpha_academy/ae_smart_contract.png'
import SatContract from '../img/alpha_academy/sat_contract.jpg'
import SatContractLQ from '../img/alpha_academy/sat_contract-lq.jpg'
import AzgaContract from '../img/alpha_academy/azga-contract.jpg'
import AzgaContractLQ from '../img/alpha_academy/azga-contract-lq.jpg'

import escapeRoomHero from '../img/synergy/escape-room/escape-room-lander.jpg'
import escapeRoomHeroLQ from '../img/synergy/escape-room/escape-room-lander-lq.jpg'

export default function SmartContracts() {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const pageTransition = {
        initial: {opacity: 0, x: 50},
        animate: {opacity: 1, x: 0},
        exit: {opacity: 0, x: 50}
    }

    return (<>
        <motion.div key="portfolio_page" className='min-h-screen flex flex-col lg:px-0 pt-20' variants={pageTransition} initial="initial" animate="animate" exit="exit">
            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-16 lg:space-y-0 '>
                    <h1 className='pb-0'>Smart Contracts</h1>
                    <h3 className='pb-16 font-light'>Alpha Academy</h3>
                <div className='w-full lg:w-4/6 2xl:w-2/4 lg:mt-[10vh] mx-auto'>
                    <div id="alphaacademy" className='text-center lg:text-left pb-16 px-4'>
                        
                        {/* Hero Image */}
                        <LazyLoadImage src={ethereumBanner} placeholderSrc={ethereumBannerLQ} className='w-full mb-8 lg:mb-16 scale-[1.15] lg:scale-100' alt="artwork collection" />

                        <aside className='text-center lg:text-left w-full mx-auto mb-16 2xl:w-3/4'>
                                <p className='text-mobile leading-5 lg:leading-5 md:text-average mt-6 font-semibold'>Key responsibilities:</p>
                                <ul className='text-mobile leading-5 lg:leading-5 list-outside ml-6 mt-3 font-light grid grid-cols-1 lg:grid-cols-3 gap-1.5 lg:gap-3'>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>📖</span> Researching and evaluating various blockchains to identify the optimal chain for deploying smart contracts</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>⚙️</span> Creating func spec document to outline core functions of the contracts</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>⛓</span> Developing and deploying ERC-721 compliant Ethereum-based smart contracts</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>✅</span> Validating contracts via Etherscan</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🥇</span> Implementing best practices for on-chain and off-chain data management</li>
                                </ul>
                                <p className='text-mobile leading-5 lg:leading-7 md:text-average mt-8 font-semibold'>Tech stack:</p>
                                <p className='text-mobile leading-5 lg:leading-7'>Solidity, Brownie, Python, Infura, Pinata IPFS decentralised Image Storage</p>
                        </aside>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large font-semibold'>
                            While Blockchain, NFTs, and Cryptocurrencies are often met with skepticism or dismissal, they offer a wealth of intriguing possibilities as technologies. The overarching vision is to craft a decentralised world where individuals have more control over their data and code transparency builds more trust in the systems we share our data with.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            To create a decentralised artwork collection, I developed and deployed several smart contracts on the Ethereum network. These contracts managed the acquisition, transfer, and verification of each artwork, recorded as immutable actions on the blockchain's ledger.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            This involved developing smart contracts in a specific manner to ensure broad compatibility with marketplaces and other NFT-centric websites. I designed and built each contract in alignment with the ERC-721 standard - the widely accepted standard for NFTs, focusing on best practices for maximal compatibility.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            One of the unique challenges in smart contract development is the cost-to-complexity ratio: the more complex the code, the higher the user cost. I concentrated on developing highly efficient code, leading to significant cost savings for customers. While similar projects saw transaction costs of up to $90, my contract kept them typically below $15—an achievement in this context.
                        </p>
                        
                    </div>
                </div>
            </div>

            {/* SMART CONTRACTS */}
            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>
                <div className='w-full lg:w-4/6 2xl:w-2/4 mx-auto text-center lg:text-left'>
                    <div className='w-full overflow-hidden mx-auto'>
                        <h3 className='mb-10'>My deployed Smart Contracts</h3>

                        <div className='grid grid-cols-3 gap-24 lg:gap-6'>

                            <div className='col-span-full lg:col-span-1 text-center'>
                                <LazyLoadImage src={AeContract} placeholderSrc={AeContract} className='w-full border-secondaryWhite/10 border-[2px]' alt="artwork collection" />
                                <a href="https://etherscan.io/address/0x692038c37f56e1772ce1d61cd0ff2d4289028458" target='_blank' alt="Alpha Elementary Contract" title="Visit on Etherscan" className='btn-secondary mt-8 transition-colors group-hover:bg-accent mx-auto hover:cursor-pointer' >Alpha Elementary</a>
                            </div>

                            <div className='col-span-full lg:col-span-1 text-center'>
                                <LazyLoadImage src={SatContract} placeholderSrc={SatContractLQ} className='w-full border-secondaryWhite/10 border-[2px]' alt="artwork collection" />
                                <a href="https://etherscan.io/address/0xB6B4cA6fE80008C429b713C26D6DCebC4Ac32893" target='_blank' alt="Show and Tell Contract" title="Visit on Etherscan" className='btn-secondary mt-8 transition-colors group-hover:bg-accent mx-auto hover:cursor-pointer' >Show &amp; Tell</a>
                            </div>

                            <div className='col-span-full lg:col-span-1 text-center'>
                                <LazyLoadImage src={AzgaContract} placeholderSrc={AzgaContractLQ} className='w-full border-secondaryWhite/10 border-[2px]' alt="artwork collection" />
                                <a href="https://etherscan.io/address/0x5f7444c89f70afa9ed8ea21060d29935e72d73d1" target='_blank' alt="AZGA Contract" title="Visit on Etherscan" className='btn-secondary mt-8 transition-colors group-hover:bg-accent mx-auto hover:cursor-pointer' >AZGA</a>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>
                <motion.div initial={{opacity: 0, y: 30}} whileInView={{opacity: 1, y: 0, transition: {delay: 0.5}}} viewport={{once: true, margin: "-100px 0px 0px 0px"}} key="upnext"
                className='w-3/4 lg:w-1/4 2xl:w-1/6 mx-auto text-center snap-center hover:cursor-pointer group flex flex-col lg:place-content-between py-20'>
                    <h3 className='mt-6 mb-4'>View next 👇</h3>
                    <Link to="/space-escape" title="View the project" className=''>
                        <LazyLoadImage src={escapeRoomHero} placeholderSrc={escapeRoomHeroLQ} className='w-full' alt="artwork collection" />
                        <div className='flex flex-col'>
                        <h3 className='mt-10 mb-4'>Synergy Space Escape</h3>
                        <p className='text-mobile'>
                            The mission: educate children age 8 - 10 on the basics of how energy works. The challenge: make it fun. The solution: a mobile escape room that turned them into explorers and launched them into the stars to working together in order setup an advanced, energy efficient colony on a new world.
                        </p>
                        <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent mx-auto' title="View the project">
                            View Project
                        </div>
                        </div>
                    </Link>
                </motion.div>
            </div>

        </motion.div >
    </>);
}