import { useState } from "react";

function Authorise(props) {

    const whitelist = ["canva2023", "testlink", "hogbenpreview", "noauth"];
    const [inputValue, setInputValue] = useState("");
    
    function handleSubmit(events) {
        events.preventDefault();
        // update local storage
        let tmpPass = inputValue.toLowerCase()
        if(whitelist.includes(tmpPass)) {
            localStorage.setItem('accessGranted', 'true');
            props.auth(true);
        } else {
            alert("Incorrect password")
        }
    }

    function handleChange(event) {
        setInputValue(event.target.value); // update inputValue when the input changes
    }

    return (
      <div className="grid w-screen min-h-screen items-center place-items-center bg-almostBlack">
        <div className="text-center w-full px-4 lg:w-1/2 2xl:w-1/4">
            <h2 className="mb-6">Welcome to the portfolio of Chris Hogben</h2>
            <p className="text-mobile">Currently my site is restricted to those given access. If you have a password, please input it in the field below.</p>
            <form onSubmit={handleSubmit} className="mx-autospace-x-0 space-y-4 lg:space-x-4 lg:space-y-0 mt-6">
                <input type="text" className="px-3 py-2 text-offWhite bg-black border-[1px] border-secondaryWhite/30 block lg:inline-block mx-auto w-full lg:w-60 text-center lg:text-left" name="access" placeholder="Enter Password" value={inputValue} onChange={handleChange} />
                <button type="submit btn block lg:inline-block" className=""> Submit</button>
            </form>
        </div>
      </div>
    );
}

export default Authorise;
