import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import VideoComponent from './videoPlayer';

// image tiles
import vppHero from '../img/synergy/vpp/vpp-hero-img.jpg'
import vppHeroLQ from '../img/synergy/vpp/vpp-hero-img-lq.jpg'

import atlassian from '../img/atlassian/att-20-tile.png'
import atlassianLQ from '../img/atlassian/att-20-tile-lq.jpg'

import vppVideo from '../videos/vpp-ar-demo.mp4'

export default function VppAr() {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const pageTransition = {
        initial: {opacity: 0, x: 50},
        animate: {opacity: 1, x: 0},
        exit: {opacity: 0, x: 50}
    }

    return (<>
        <motion.div key="portfolio_page" className='min-h-screen flex flex-col lg:px-0 pt-20' variants={pageTransition} initial="initial" animate="animate" exit="exit">
            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-16 lg:space-y-0 '>
                    <h1 className='pb-0'>Synergy Virtual Power Plants (VPP) <br /> AR Experience</h1>
                    <h3 className='pb-16 font-light'>Synergy Energy</h3>
                <div className='w-full lg:w-4/6 2xl:w-2/4 lg:mt-[10vh] mx-auto'>
                    <div id="alphaacademy" className='text-center lg:text-left pb-16 px-4'>
                        
                        {/* Hero Image */}
                        <LazyLoadImage src={vppHero} placeholderSrc={vppHeroLQ} className='w-full mb-8 lg:mb-16 scale-[1.15] lg:scale-100' alt="artwork collection" />

                        <aside className='text-center lg:text-left w-full mx-auto mb-16 2xl:w-3/4'>
                                <p className='text-mobile leading-5 lg:leading-5 md:text-average mt-6 font-semibold'>Key responsibilities:</p>
                                <ul className='text-mobile leading-5 lg:leading-5 list-outside ml-6 mt-3 font-light grid grid-cols-1 lg:grid-cols-2 gap-1.5 lg:gap-3'>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🧬</span> Concept development &amp; ideation</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🖼</span> Oversee development of creative asset development</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🚀</span> Work with our AR partners facilitate development</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🛟</span> Create documentation and troubleshooting guides</li>
                                </ul>
                        </aside>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large font-semibold'>
                            With Synergy announcing thier Virtual Power Plant (VPP) project, a tool was needed to help their SMEs simplify down the concept into something visual, engaging and interactive. As the creative technologist, I developed the concept of an AR experience that SMEs would showcase to clients at various trade shows and events.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            The solution was to develop a 3D city asset that showcased various scenarios where the VPP would deliver value to business, residents and the community. This broke down the concept into something visual and captivating, while allowing the SME to lead the experience, ask questions and help facilitate a conversation.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            The approach was to develop an experience that was simple to use, easy to articulate and sharable. Users could share screenshots, supply an email to receive a detailed information package, and be guided by a SME who was fully trained on running the experience so that the customer could focus on the content.
                            <br />
                            <br />
                            See a showcase of the experience below.
                        </p>
                        
                    </div>
                </div>
            </div>

            {/* VPP VIDEO */}
            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>
                <div className='w-full lg:w-4/6 2xl:w-2/4 mx-auto text-center lg:text-left'>
                <VideoComponent source={vppVideo} autoplay={false} loop={false} className="pb-10 pt-2 lg:pt-8 scale-100" />
                </div>
            </div>

            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>
                <motion.div initial={{opacity: 0, y: 30}} whileInView={{opacity: 1, y: 0, transition: {delay: 1}}} viewport={{once: true, margin: "-100px 0px 0px 0px"}} key="upnext"
                className='w-3/4 lg:w-1/4 2xl:w-1/6 mx-auto text-center snap-center hover:cursor-pointer group flex flex-col lg:place-content-between py-20'>
                    <h3 className='mt-6 mb-4'>View next 👇</h3>
                    <Link to="/atlassian" title="View the project" className=''>
                        <LazyLoadImage src={atlassian} placeholderSrc={atlassianLQ} className='w-full' alt="atl tty" />
                        <div className='flex flex-col'>
                        <h3 className='mt-10 mb-4'>Atlassian: Through The Years</h3>
                            <p className='text-mobile'>
                                'Atlassian: Through The Years' commemorated Atlassian's 20th anniversary, serving as a vibrant reflection of their rich history, culture, and values. An immersive web3D journey, this interactive experience allowed staff members to hear founding voices, uncover hidden artefacts, and collect virtual mementos, all while exploring the company's most pivotal moments across time and space.
                            </p>
                            <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent mx-auto' title="View the project">
                                View Project
                            </div>
                        </div>
                    </Link>
                </motion.div>
            </div>

        </motion.div >
    </>);
}