import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// image tiles
import escapeRoomHero from '../img/synergy/escape-room/escape-room-lander.jpg'
import escapeRoomHeroLQ from '../img/synergy/escape-room/escape-room-lander-lq.jpg'

import escapeLab from '../img/synergy/escape-room/escape-room-lab-2.jpg'
import escapeLabLQ from '../img/synergy/escape-room/escape-room-lab-lq.jpg'
import toLander from '../img/synergy/escape-room/escape-room-lander-2.jpg'
import toLanderLQ from '../img/synergy/escape-room/escape-room-lander-2-lq.jpg'
import escapeRender from '../img/synergy/escape-room/space-escape-render.jpg'
import escapeRenderLQ from '../img/synergy/escape-room/space-escape-render-lq.jpg'


import energyOdysseyTile from '../img/synergy/energy-odyssey/energy-odyssey-tile.jpg'
import energyOdysseyTileLQ from '../img/synergy/energy-odyssey/energy-odyssey-tile-lq.jpg'

export default function SpaceEscape() {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const pageTransition = {
        initial: {opacity: 0, x: 50},
        animate: {opacity: 1, x: 0},
        exit: {opacity: 0, x: 50}
    }

    return (<>
        <motion.div key="portfolio_page" className='min-h-screen flex flex-col lg:px-0 pt-20' variants={pageTransition} initial="initial" animate="animate" exit="exit">
            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-16 lg:space-y-0 '>
                    <h1 className='pb-0'>Synergy Space Escape</h1>
                    <h3 className='pb-16 font-light'>Synergy Energy</h3>
                <div className='w-full lg:w-4/6 2xl:w-2/4 lg:mt-[10vh] mx-auto'>
                    <div id="spaceescape" className='text-center lg:text-left pb-16 px-4'>
                        
                        {/* Hero Image */}
                        <LazyLoadImage src={escapeRoomHero} placeholderSrc={escapeRoomHeroLQ} className='w-full mb-8 lg:mb-16 scale-[1.15] lg:scale-100' alt="artwork collection" />

                        <aside className='text-center lg:text-left w-full mx-auto mb-16 2xl:w-3/4'>
                                <p className='text-mobile leading-5 lg:leading-5 md:text-average mt-6 font-semibold'>Key responsibilities:</p>
                                <ul className='text-mobile leading-5 lg:leading-5 list-outside ml-6 mt-3 font-light grid grid-cols-1 lg:grid-cols-3 gap-1.5 lg:gap-6'>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🧬</span> Concept development &amp; ideation</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🪄</span> Oversee with spatial designers and concept artist to develop the experience</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>⚖️</span> Work with Synergy stakeholders to provide relevant documents and assets for internal company presentations</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🧩</span> Development of escape room challenges</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🛠</span> Provide technical direction and solutions</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🖼</span> Oversee development of creative asset development</li>
                                </ul>
                        </aside>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large font-semibold'>
                            A one-of-a-kind learning experience that journeyed into the stars and turned school kids into astronauts and explorers.
                            <br />
                            <br />
                            The Synergy Space Escape Experience was designed in alignment with Western Australia's Board Of Studies energy literacy curriculum. The objective was to craft an engaging, educational experience that would illuminate key energy topics for students.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            As the creative lead, I conceived of a space-themed escape room. This immersive and captivating approach to learning was designed to create lasting memories from the 30-minute sessions.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            The idea of a space-based challenge transformed the educational experience into a thrilling adventure. Backed by research, this setting was chosen over more grounded alternatives (e.g., an apartment-based experience). It painted a vivid theme that we infused into every aspect of the experience. The scenario cast groups of four children as astronaut explorers, who, upon discovering a new planet, were tasked with building a sustainable colony there. Success in their tasks would culminate in a thrilling landing sequence, granting the young explorers a place on the leaderboard.
                        </p>
                        
                    </div>
                </div>
            </div>

            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>

                <div className='grid grid-cols-4 gap-6'>
                    <div className='col-span-full lg:col-span-2'>
                        <LazyLoadImage src={escapeLab} placeholderSrc={escapeLabLQ} className='w-full h-full object-cover' alt="artwork collection" />
                    </div>
                    <div className='col-span-full lg:col-span-2'>
                        <LazyLoadImage src={toLander} placeholderSrc={toLanderLQ} className='w-full h-full object-cover' alt="artwork collection" />
                    </div>
                </div>

            </div>

            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>

                <div className='w-full lg:w-4/6 2xl:w-2/4 mx-auto text-center lg:text-left'>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            I crafted six unique challenges, each corresponding to the Board of Studies' learning outcomes, ensuring comprehensive incorporation of the educational content. Working with a hardware supplier and our in-house 3D environmental design team, we developed an incredible, futuristic destination.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            To evaluate the effectiveness of each puzzle, I conducted user testing sessions, documenting observations, formulating interview questions, and iterating on the challenges based on our findings.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            In collaboration with a local construction company, we built a mobile escape room, transforming a tired looking Volvo school bus into a futuristic spaceship complete with windows that provided a captivating view into space. I used AWS Lex to create an AI assistant to provide narration, guidance and assist students if they struggled. The challenges made use of NFC technology, hand cranks, physical switches, keys, and environmental effects, crafting a truly unforgettable experience for all young explorers who embarked on this journey.
                        </p>

                        <LazyLoadImage src={escapeRender} placeholderSrc={escapeRenderLQ} className='w-full mt-16' alt="artwork collection" />
                </div>
            </div>

            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>
                <motion.div initial={{opacity: 0, y: 30}} whileInView={{opacity: 1, y: 0, transition: {delay: 0.5}}} viewport={{once: true, margin: "-100px 0px 0px 0px"}} key="upnext"
                className='w-3/4 lg:w-1/4 2xl:w-1/6 mx-auto text-center snap-center hover:cursor-pointer group flex flex-col lg:place-content-between py-20'>
                    <h3 className='mt-6 mb-4'>View next 👇</h3>
                    <Link to="/energy-odyssey" title="View the project">
                        <div className='flex flex-col'>
                        <LazyLoadImage src={energyOdysseyTile} placeholderSrc={energyOdysseyTileLQ} className='w-full' alt="energy odyssey" />
                            <h3 className='mt-10 mb-4'>Energy Odyssey</h3>
                            <p className='text-mobile'>
                                As part of the wider 'Power Up' campaign targeting customers struggling with energy bills, we launched Energy Odyssey. This side-scrolling game put players in control of Renny the Quokka, on its mission to save Western Australia from energy-hungry appliances. Using gamification, we reinforced positive energy habits within the at-risk community.
                            </p>
                        </div>
                        <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent ml-auto lg:ml-0 mr-auto' title="View the project">
                            View Project
                        </div>
                    </Link>
                </motion.div>
            </div>

        </motion.div >
    </>);
}