import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import VideoComponent from './videoPlayer';


// image tiles
import energyOdysseyHero from '../img/synergy/energy-odyssey/energy-odyssey-hero.jpg'
import energyOdysseyHeroLQ from '../img/synergy/energy-odyssey/energy-odyssey-hero-lq.jpg'

import vppTile from '../img/synergy/vpp/vpp-tile.jpg'
import vppTileLQ from '../img/synergy/vpp/vpp-tile-lq.jpg'

import energyOdysseyVideo from '../videos/energy-odyssey-gameplay.mp4'

export default function EnergyOdyssey() {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const pageTransition = {
        initial: {opacity: 0, x: 50},
        animate: {opacity: 1, x: 0},
        exit: {opacity: 0, x: 50}
    }

    return (<>
        <motion.div key="portfolio_page" className='min-h-screen flex flex-col lg:px-0 pt-20' variants={pageTransition} initial="initial" animate="animate" exit="exit">
            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-16 lg:space-y-0 '>
                    <h1 className='pb-0'>Energy Odyssey</h1>
                    <h3 className='pb-16 font-light'>Synergy Energy</h3>
                <div className='w-full lg:w-4/6 2xl:w-2/4 lg:mt-[10vh] mx-auto'>
                    <div id="alphaacademy" className='text-center lg:text-left pb-16 px-4'>
                        
                        {/* Hero Image */}
                        <LazyLoadImage src={energyOdysseyHero} placeholderSrc={energyOdysseyHeroLQ} className='w-full mb-8 lg:mb-16 scale-[1.15] lg:scale-100' alt="artwork collection" />

                        <aside className='text-center lg:text-left w-full mx-auto mb-16 2xl:w-3/4'>
                                <p className='text-mobile leading-5 lg:leading-5 md:text-average mt-6 font-semibold'>Key responsibilities:</p>
                                <ul className='text-mobile leading-5 lg:leading-5 list-outside ml-6 mt-3 font-light grid grid-cols-1 lg:grid-cols-3 gap-1.5 lg:gap-3'>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🧬</span> Concept development &amp;<br/> ideation</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🛠</span> Lead developer</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🎮</span> Level & game design</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🚦</span> User acceptance testing</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🖼</span> Collaborating with creative teams to develop game artwork, animations and sound design</li>
                                </ul>
                                <p className='text-mobile leading-5 lg:leading-7 md:text-average mt-8 font-semibold'>Tech stack:</p>
                                <p className='text-mobile leading-5 lg:leading-7'>Construct game engine</p>
                        </aside>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large font-semibold'>
                            The aim of the Power Up campaign was to foster improved energy habits among individuals most susceptible to bill shock. As part of this initiative, I envisioned and developed a side-scrolling game that rewarded players for completing levels and demonstrating good energy habits.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            Our research indicated a significant portion of our target audience was interested in mobile gaming, often playing games during commutes or at-home downtime. This insight directed us towards utilising gaming as a platform to enhance energy literacy and build customer confidence. Through engagement with the game, players could earn actual discounts on their energy bills, integrating fun and practical benefits. Gaming served as an effective medium for introducing and reinforcing concepts, given that good game design fosters pattern recognition and mastery.
                        </p>

                        
                    </div>
                </div>
            </div>

             {/* GAMEPLAY VIDEO */}
            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>
                <div className='w-full lg:w-4/6 2xl:w-2/4 mx-auto text-center lg:text-left'>
                <VideoComponent source={energyOdysseyVideo} autoplay={false} loop={false} className="pb-10 pt-2 lg:pt-8 scale-100" />
                </div>
            </div>

            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>

                <div className='w-full lg:w-4/6 2xl:w-2/4 mx-auto text-center lg:text-left'>
                
                    <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                        I lead development of the game the game's development using the Construct game engine. This approach facilitated swift development, testing, and deployment of our game, while allowing us to incorporate customer input during our development sprints for continuous feedback.
                    </p>

                    <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                        Energy Odyssey emerged as the premier game of the Power Up campaign. The platform was made available to at-risk groups, providing a valuable and entertaining resource for learning.
                    </p>
                    
                </div>
            </div>

            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>
                <motion.div initial={{opacity: 0, y: 30}} whileInView={{opacity: 1, y: 0, transition: {delay: 0.5}}} viewport={{once: true, margin: "-100px 0px 0px 0px"}} key="upnext"
                className='w-3/4 lg:w-1/4 2xl:w-1/6 mx-auto text-center snap-center hover:cursor-pointer group flex flex-col lg:place-content-between py-20'>
                    <h3 className='mt-6 mb-4'>View next 👇</h3>
                    <Link to="/vpp-ar" title="Virtual Power Plants AR">
                        <div className='flex flex-col'>
                            <LazyLoadImage src={vppTile} placeholderSrc={vppTileLQ} className='w-full' alt="artwork collection" />
                            <h3 className='mt-10 mb-4'>Synergy Virtual Power Plants (VPP) AR Experience</h3>
                            <p className='text-mobile'>
                                An augmented reality tool that armed SMEs with an immersive toolkit. This innovative tool empowered them to better demonstrate Synergy Energy's groundbreaking approach to decentralised power sharing.
                            </p>
                        </div>
                        <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent bottom-0 ml-auto lg:ml-0 mr-auto' title="View the project">
                            View Project
                        </div>
                    </Link>
                </motion.div>
            </div>

        </motion.div >
    </>);
}