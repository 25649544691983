
function VideoComponent({source, autoplay, className, loop}) {
    return (
      <div>
        <video className={`w-full rounded-lg overflow-hidden ${className}`} controls autoPlay={autoplay} loop={loop}>
          <source src={source} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
}

export default VideoComponent;
