import { motion, AnimatePresence } from "framer-motion";
import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";

// Nav
import Navigation from './nav.js';

// Routes
import HomePage from './pages/home.js';
import Portfolio from './pages/portfolio.js';
import Authorise from './authorise.js'
// Alpha Academy
import ArtworkGenerator from './pages/artworkGenerator.js';
import AlphaWebsite from './pages/alphaWebsite.js';
import SmartContracts from './pages/smartContracts.js';
// Synergy
import SpaceEscape from './pages/spaceEscape.js';
import EnergyOdyssey from './pages/energyOdyssey.js';
import VppAr from './pages/vppAr.js';

import Atlassian from './pages/atlassian.js';
import NbnDc from './pages/nbndc.js';

// AI Test
import HoroScope from './pages/horoscope.js';

function AnimatedPages(props) {
    const location = useLocation();

    const [isAuthorised, setIsAuthorised] = useState(false);

    const auth = new URLSearchParams(location.search).get('auth');
    
    const whitelist = ["canva2023", "testlink", "hogbenpreview", "noauth"];
  
    // auto authenticate if URL includes whitelisted params
    useEffect(()=> {
      // check if user has previously been authorised
      const accessGranted = localStorage.getItem('accessGranted');
      
      let tempAuth = String(auth).toLowerCase();

      if (accessGranted) {
        setIsAuthorised(true);
      } else if(whitelist.includes(tempAuth)) {
        // if no local storage iteme exists, check if url auth provided
        setIsAuthorised(true)

        // update local storage
        localStorage.setItem('accessGranted', 'true');
      }
    }, [])
    
    if(!isAuthorised) {
      return <Authorise auth={setIsAuthorised} />
    }

  

    return(
        <Navigation>
            <AnimatePresence mode="wait">
                <Routes key={location.pathname} location={location}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/portfolio" element={<Portfolio />} />

                    {/* Alpha Academy */}
                    <Route path="/artwork-generator" element={<ArtworkGenerator />} />
                    <Route path="/alpha-website" element={<AlphaWebsite />} />
                    <Route path="/smart-contracts" element={<SmartContracts />} />

                    {/* Synergy Energy */}
                    <Route path="/space-escape" element={<SpaceEscape />} />
                    <Route path="/energy-odyssey" element={<EnergyOdyssey />} />
                    <Route path="/vpp-ar" element={<VppAr />} />

                    {/* Other */}
                    <Route path="/atlassian" element={<Atlassian />} />
                    <Route path="/nbndc" element={<NbnDc />} />
                    
                    {/* AI TEST */}
                    <Route path="/horoscope" element={<HoroScope />} />

                </Routes>
            </AnimatePresence>
        </Navigation>
    )
}

export default AnimatedPages;