import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga4';

import './App.css';
import AnimatedPages from './AnimatedRoutes'
// import Navigation from './nav.js';

ReactGA.initialize("G-JYP4KNMWCB");
// ReactGA.pageview(window.location.pathname + window.location.search);

function App() {

  return (
    <Router>
      <AnimatedPages />
    </Router>
  );
}

export default App;
