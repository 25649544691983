import { Link } from 'react-router-dom';
import HogbenLogo from './img/hogben-logo-w.svg';
import { useLocation } from "react-router-dom";
import { motion } from 'framer-motion';

import HomeIcon from './img/home-ico.svg';
import PortfolioIcon from './img/portfolio-ico.svg';

import resume from './Chris_Hogben_Resume_Creative_Technologist.pdf'

export default function Navigation({ children }) {
    const location = useLocation();

    const hideNav = {
        initial: {opacity: 0},
        animate: {opacity: 0},
        exit: {opacity: 0}
    }

    const showNav = {
        initial: {opacity: 0},
        animate: {opacity: 1, transition: {delay: 1}},
        exit: {opacity: 0}
    }

    const showPortfolioIcon = {
        initial: {opacity: 0, x: 50},
        animate: {opacity: 1, x: 0, transition: {duration: 0.2, delay: 0.5}},
        exit: {opacity: 0}
    }

    const hidePortfolioIcon = {
        initial: {opacity: 0, x: 50},
        animate: {opacity: 0, x: 50, transition: {duration: 0.2}},
        exit: {opacity: 0}
    }

    const homeLeft = {
        initial: {x: 50},
        animate: {x: 50, transition: {duration: 0.2, delay: 0.5}},
        exit: {opacity: 0}
    }

    const homeRight = {
        initial: {x: 50},
        animate: {x: 0, transition: {duration: 0.2, delay: 0.5}},
        exit: {opacity: 0}
    }

    return (<>
        <div className='bg-black w-screen'>
            <motion.nav variants={location.pathname == '/' ? hideNav : showNav} initial="initial" animate="animate" exit="exit" className={`absolute flex flex-row w-screen place-content-between px-4 lg:px-8 h-20 place-items-center`}>
                <Link to="/">
                    <img src={HogbenLogo} alt="Immutable Canvas" className='w-32 z-40' />
                </Link>
                <div className='flex flex-row space-x-4 overflow-hidden'>
                    <motion.div variants={(location.pathname != '/portfolio') ? homeRight : homeLeft} initial="initial" animate="animate" exit="exit" key="home_nav_link" className='flex'>
                        <Link to="/" key="home-btn" className='hover:bg-accent active:bg-[#2C217E] transition-all rounded-full px-2 py-2'><img src={HomeIcon} className='w-6' title="Navigate Home" /></Link>
                    </motion.div>
                    <motion.div variants={(location.pathname != '/portfolio') ? showPortfolioIcon : hidePortfolioIcon} initial="initial" animate="animate" exit="exit" key="portfolio_nav_link" className='flex'>
                        <Link to="/portfolio" className='hover:bg-accent active:bg-[#2C217E] transition-all rounded-full px-2 py-2'><img src={PortfolioIcon} className='w-6' title="Back to Portfolio" /></Link>
                    </motion.div>
                </div>
            </motion.nav>
            <main role="main" className={`flex-grow ${location.pathname == '/' ? hideNav : showNav}`}>
                { children }
            </main>
            <footer className={`py-16 px-8 w-full flex-col ${location.pathname == '/' ? "hidden md:flex" : "flex"}`}>
                <div className='pb-12 md:pb-5 flex flex-col md:flex-row lg:space-x-16 md:space-x-10 space-x-0 lg:space-y-0 md:space-y-0 space-y-6  mx-auto w-auto text-center'>
                    <a href="mailto:chris.edhogben@gmail.com" alt="contact me" className='text-[1.2rem] md:text-large text-secondaryWhite hover:text-offWhite transition-colors'><span className='mr-2'>✉️</span>chris.edhogben@gmail.com</a>
                    <a href="https://www.notion.so/Chris-Hogben-bc76d08a83094efab45a823133abcb9d#9b7465aa003e4db9be92365113b87728" alt="contact me" target='_blank' className='text-[1.2rem] md:text-large text-secondaryWhite hover:text-offWhite transition-colors'><span className='mr-2'>📄</span>View my resume</a>
                </div>
                <p className='text-secondaryWhite select-none text-center'>
                    Built with ❤️  by Chris, 2023.
                </p>
            </footer>
        </div>
    </>);
}