'use client';
 
import { useCompletion } from 'ai/react';

import React, { useEffect, useState} from 'react';
import { motion } from 'framer-motion';


export default function HoroScope() {
    // const { messages, input, handleInputChange, handleSubmit } = useChat()
    const { completion, input, handleInputChange, handleSubmit } = useCompletion();
    const [message, setMessage] = useState("")

    const handleClick = async() => {
        const response = await fetch('/api/chat');
        const text = await response.text();
        setMessage(text)
    }

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const pageTransition = {
        initial: {opacity: 0, x: 50},
        animate: {opacity: 1, x: 0},
        exit: {opacity: 0, x: 50}
    }

    return (<>
        <motion.div key="portfolio_page" className='min-h-screen flex flex-col lg:px-0 pt-20' variants={pageTransition} initial="initial" animate="animate" exit="exit">
            <div className='container'>
                {/* { messages.length > 0 ? 
                messages.map((m) => (
                    <div key={m.id}>
                        {m.role == "user" ? "User: " : "AI: "}
                        {m.content}
                    </div>
                ))
                :
                null
                }

                <div className=''>
                    <form onSubmit={handleSubmit}>
                        <input
                            className='fixed w-1/2 max-w-md bottom-0 border-gray-300 rounded mb-8 shadow-xl p-2'
                            value={input}
                            placeholder='Say something...'
                            onChange={handleInputChange}
                        />
                    </form>
                </div> */}
            </div>
            <button onClick={handleClick} className='btn'>Get message</button>
            <div className='bg-slate-600'>{message}</div>
        </motion.div>
    </>);
}