import React, { useEffect} from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

import HogbenLogo from '../img/hogben-logo-w.svg';

export default function Home() {

    useEffect(() => {
        // When the component mounts
        document.body.style.position = 'fixed';
        document.body.style.overflow = 'hidden';
        document.body.style.width = '100%';
        document.body.style.height = '100%';

        // When the component unmounts
        return () => {
            document.body.style.position = '';
            document.body.style.overflow = '';
            document.body.style.width = '';
            document.body.style.height = '';
        };
    }, []); // Empty array means this effect runs once on mount and clean up on unmount


    const logoAnim = {
        initial: {opacity: 1, scale: 0.93},
        animate: {opacity: 1, scale: 1, x: 0,
            transition: {
                delay: 0,
                duration: 1.5
            }
        },
        exit: {opacity: 0, x: -10}
    }

    const textAnim = {
        initial: {opacity: 0, y: 20},
        animate: {opacity: 1, y: 0, x: 0,
            transition: {
                delay: 1.3,
                duration: 0.7
            }
        },
        exit: {opacity: 0, x: -10}
    }

    return (<>
        <div key="home_page" className='min-h-screen flex px-8 lg:px-0 relative overflow-hidden'>
            <iframe src={`/animation/index.html`}  height="100%" width="100%" className='fixed md:absolute overflow-hidden top-2 md:top-1 left-0 px-0 lg:px-4 py-1 lg:py-24 md:px-24 ' scrolling='no' />
            <div className="text-white my-auto mx-auto z-50 w-full lg:w-1/3 pt-52 pointer-events-none">
            <motion.img variants={logoAnim} initial="initial" animate="animate" exit="exit" key="hogben_logo" src={HogbenLogo} alt="Chris Hogben portfolio site" className='w-full mx-auto -mt-52 lg:mt-0' />
            
                <motion.div variants={textAnim} initial="initial" animate="animate" exit="exit" className='text-center mt-36 select-none w-full absolute bottom-32 lg:bottom-0 lg:w-auto lg:relative -ml-6 lg:ml-0 '>
                    <h3 className='text-[1rem] lg:text-[1rem] text-secondaryWhite'>Welcome to the portfolio of</h3>
                    <h3 className='text-[1.2rem] lg:text-[1.2rem] text-offWhite font-semibold mt-1'>Chris Hogben, <br className='block md:hidden' />Creative Technologist</h3>
                    <motion.span>
                        <Link className='btn mt-8 hover:cursor-pointer pointer-events-auto' to="/portfolio" title="View my portfolio">Portfolio</Link>
                    </motion.span>
                </motion.div>
            </div>
        </div>
    </>);
}

