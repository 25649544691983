import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import VideoComponent from './videoPlayer';

// image tiles
import globeView from '../img/atlassian/att-globe.jpg'
import globeViewLQ from '../img/atlassian/att-globe-lq.jpg'

import interactionFunc from '../img/atlassian/atl-function.png'
import interactionFuncLQ from '../img/atlassian/atl-function-lq.jpg'

import nbndc from '../img/nbn/nbn-tile.jpg'
import nbndcLQ from '../img/nbn/nbn-tile-lq.jpg'

import attVideo from '../videos/atl-vid.mp4'

export default function Atlassian() {

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    const pageTransition = {
        initial: {opacity: 0, x: 50},
        animate: {opacity: 1, x: 0},
        exit: {opacity: 0, x: 50}
    }

    return (<>
        <motion.div key="portfolio_page" className='min-h-screen flex flex-col lg:px-0 pt-20' variants={pageTransition} initial="initial" animate="animate" exit="exit">
            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-16 lg:space-y-0 '>
                    <h1 className='pb-0'>Through The Years</h1>
                    <h3 className='pb-16 font-light'>Atlassian</h3>
                <div className='w-full lg:w-4/6 2xl:w-2/4 lg:mt-[10vh] mx-auto'>
                    <div id="alphaacademy" className='text-center lg:text-left pb-16 px-4'>
                        
                        {/* Hero Image */}
                        <LazyLoadImage src={globeView} placeholderSrc={globeViewLQ} className='w-full mb-8 lg:mb-16 scale-[1.15] lg:scale-100' alt="artwork collection" />

                        <aside className='text-center lg:text-left w-full mx-auto mb-16 2xl:w-3/4'>
                                <p className='text-mobile leading-5 lg:leading-5 md:text-average mt-6 font-semibold'>Key responsibilities:</p>
                                <ul className='text-mobile leading-5 lg:leading-5 list-outside ml-6 mt-3 font-light grid grid-cols-1 lg:grid-cols-3 gap-1.5 lg:gap-6'>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🧬</span> Concept development<br className='hidden lg:block'/> &amp; ideation</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>📝</span> Produce wireframes, IA maps and presentation decks</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🖥</span> Develop platform scope and features</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>⚙️</span> Writing func spec documents</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🚀</span> Work closely with development team to manage platform development</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🤝</span> Recruiting, interviewing and managing UI artists & specialised 3D artists</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🚦</span> Conducting user testing</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🛟</span> Developing onboarding tools & documentation</li>
                                </ul>
                        </aside>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large font-semibold'>
                            As Atlassian prepared to commemorate their 20th anniversary, the company's structure had grown increasingly dispersed, with staff operating from offices and homes worldwide. To celebrate this significant milestone, I worked with our creative director to conceive of a web-based 3D experience, helping our team understand the various technologies and their pitfalls so that we could choose the best solution. The platform we developed offered rich touch points, gamification, and social elements, crafting an interactive journey through Atlassian's history accessible from any modern internet-connected device.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                        To maintain an ambitious yet feasible project scope, I devised a core functionality document. This defined the platform's capabilities, providing framework to work in. Notable features included the ability to leave post-it notes with anecdotes and reflections for colleagues, a conversational Q&A system facilitating engagement with founding members, a collectibles system encouraging exploration, and integrated videos and imagery.
                        </p>
                        <LazyLoadImage src={interactionFunc} placeholderSrc={interactionFuncLQ} className='w-full pt-10 mb-16 lg:mb-20 scale-[1.15] lg:scale-100' alt="artwork collection" />

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            I closely collaborated with our UI/UX designers to construct a straightforward, effective navigation system, and managed 3D resources to ensure asset optimisation, detail retention, and cohesive aesthetics.
                        </p>
                        
                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            As part of my role, I presented at weekly meetings with the Atlassian marketing team, offering project updates, functionality demonstrations, and managing expectations regarding a web-optimised 3D experience.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            I created walkthrough videos, onboarding documents, and cheat sheets to equip stakeholders with crucial information about the platform's capabilities and potential future opportunities.
                        </p>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            The final product was a heartfelt, nostalgic, and entertaining journey through time, spanning from Mike & Scott's basement office to a futuristic Atlassian HQ in Sydney. The experience stirred fond memories among staff members, who shared and enriched these cherished moments via Slack channels, keenly exploring each diorama to discover every memento and hidden piece of memorabilia.
                        </p>
                        
                    </div>
                </div>
            </div>


            {/* SHOW AND TELL */}
            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pb-10 lg:space-y-0 '>
                <div className='w-full lg:w-4/6 2xl:w-2/4 mx-auto text-center lg:text-left'>
                    <VideoComponent source={attVideo} autoplay={false} loop={false} className="pb-10 pt-2 lg:pt-8 scale-100" />
                    
                    <div className='flex flex-col 2xl:w-3/4 lg:flex-row mt-16 lg:space-x-16 mx-auto'>
                    <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            The final product was a heartfelt, nostalgic, and entertaining journey through time, spanning from Mike & Scott's basement office to a futuristic Atlassian HQ in Sydney. The experience stirred fond memories among staff members, who shared and enriched these cherished moments via Slack channels, keenly exploring each diorama to discover every memento and hidden piece of memorabilia.
                        </p>
                    </div>
                </div>
            </div>

            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>
                <motion.div initial={{opacity: 0, y: 30}} whileInView={{opacity: 1, y: 0, transition: {delay: 1}}} viewport={{once: true, margin: "-100px 0px 0px 0px"}} key="upnext"
                className='w-3/4 lg:w-1/4 2xl:w-1/6 mx-auto text-center snap-center hover:cursor-pointer group flex flex-col lg:place-content-between py-20'>
                    <h3 className='mt-6 mb-4'>View next 👇</h3>
                    <Link to="/nbndc" title="View the project" className=''>
                    <LazyLoadImage src={nbndc} placeholderSrc={nbndcLQ} className='w-full' alt="nbn discovery center" />
                        <div className='flex flex-col'>
                        <h3 className='mt-10 mb-4'>NBN Discovery Centre</h3>
                            <p className='text-mobile'>
                                The NBN Discovery Centre was a physical space to bring in enterprise clients, small businesses and community groups and showcase how NBN broadband is helping shape the future of Australia. Built around the idea of NBN as the fabric of Australia, it leveraged an array of technologies to bring various stories to life
                            </p>
                            <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent mx-auto' title="View the project">
                                View Project
                            </div>
                        </div>
                    </Link>
                </motion.div>
            </div>

        </motion.div >
    </>);
}