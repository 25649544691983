import React, { useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
import { motion, useInView } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import VideoComponent from './videoPlayer';

// image tiles
import genBrowse from '../img/alpha_academy/generator-browse.png'
import genBrowseLQ from '../img/alpha_academy/generator-browse-lq.jpg'

import flatAE from '../img/alpha_academy/artwork-breakdown/ae_final.jpg'
import flatAELQ from '../img/alpha_academy/artwork-breakdown/ae_final-lq.jpg'

import artworkLayer0 from '../img/alpha_academy/artwork-breakdown/background.png'
import artworkLayer1 from '../img/alpha_academy/artwork-breakdown/layer-1.png'
import artworkLayer2 from '../img/alpha_academy/artwork-breakdown/layer-2.png'
import artworkLayer3 from '../img/alpha_academy/artwork-breakdown/layer-3.png'
import artworkLayer4 from '../img/alpha_academy/artwork-breakdown/layer-4.png'
import artworkLayer5 from '../img/alpha_academy/artwork-breakdown/layer-5.png'
import artworkLayer6 from '../img/alpha_academy/artwork-breakdown/layer-6.png'
import artworkLayer7 from '../img/alpha_academy/artwork-breakdown/layer-7.png'

import alphaWebsiteTile from '../img/alpha_academy/aa-website-tile.jpg'
import alphaWebsiteTileLQ from '../img/alpha_academy/aa-website-tile-lq.jpg'

import genicronVideo from '../videos/genicron-site.mp4'

export default function ArtworkGenerator() {

    const [ shouldAnimate, setShouldAnimate] = useState(false);
    const ref = useRef(null)
    const isInView = useInView(ref);

    useEffect(() => {
        window.scrollTo(0,0)
    }, [])

    useEffect(()=>{
        if(isInView && !shouldAnimate) {
            setShouldAnimate(true)
        }
    }, [isInView])

    const pageTransition = {
        initial: {opacity: 0, x: 50},
        animate: {opacity: 1, x: 0},
        exit: {opacity: 0, x: 50}
    }

    const containerVariants = {
        initial: { opacity: 1 },
        animate: {
          opacity: 1,
          transition: {
            delayChildren: 0.5, // delay before child animations start
            staggerChildren: 0.3 // time between each child animation
          }
        },
        exit: { opacity: 1 },
      };

    const deconstructedBackground = {
        initial: {opacity: 0, y: 0},
        animate: {opacity: 1, y: 0, transition: {duration: 0.5}},
        exit: {opacity: 0, y: 0}
    }

    const deconstructedAlpha = {
        initial: {opacity: 0, y: -460},
        animate: (custom) => ({opacity: 1, y: -40 * custom, transition: {duration: 0.7}}),
        exit: {opacity: 0, y: 0},
    }


    return (<>
        <motion.div key="portfolio_page" className='min-h-screen flex flex-col lg:px-0 pt-20' variants={pageTransition} initial="initial" animate="animate" exit="exit">
            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-16 lg:space-y-0 '>
                    <h1 className='pb-0'>Artwork Generator</h1>
                    <h3 className='pb-16 font-light'>Alpha Academy</h3>
                <div className='w-full lg:w-4/6 2xl:w-2/4 lg:mt-[10vh] mx-auto'>
                    <div id="alphaacademy" className='text-center lg:text-left pb-16 px-4'>
                        
                        {/* Hero Image */}
                        <LazyLoadImage src={genBrowse} placeholderSrc={genBrowseLQ} className='w-full mb-8 lg:mb-16 scale-[1.15] lg:scale-100' alt="artwork collection" />

                        <aside className='text-center lg:text-left w-full mx-auto mb-16 2xl:w-3/4'>
                                <p className='text-mobile leading-5 lg:leading-5 md:text-average mt-6 font-semibold'>Key responsibilities:</p>
                                <ul className='text-mobile leading-5 lg:leading-5 lg:list-disc list-outside ml-6 mt-3 font-light grid grid-cols-1 lg:grid-cols-2 gap-1.5 lg:gap-3'>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>📝</span> Site map, IA & wireframing</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🖼</span> UI design</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🏗</span> Front-end development</li>
                                    <li className='col-span-1 pr-3'><span className='-ml-6'>🛠</span> Back-end development</li>
                                </ul>
                                <p className='text-mobile leading-5 lg:leading-7 md:text-average mt-8 font-semibold'>Tech stack:</p>
                                <p className='text-mobile leading-5 lg:leading-7'>React.js & Express.js with Tailwind CSS, Framer Motion and p5.js</p>
                        </aside>

                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large font-semibold'>At its core, the Alpha Academy project aimed to develop an endearing, unique, and playful character collection that the community could proudly own and utilise for online representation. In a domain where 'Bored Apes' were setting the pace, our ambition was to elevate the level of care, quality, and innovation typically associated with the NFT space.</p>
                        <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                            While generative artwork collections usually comprise 500 - 10,000 artworks appearing hastily assembled, our mission was to create a collection conveying the impression of individual attention and love in every character. As a teacher of generative art & design, I was adamant on developing a generator that was best-in-class.
                        </p>
                    </div>
                </div>
            </div>

            <div className='w-full flex bg-[#2822a5] pt-16 pb-10'>
                <div className='mx-auto w-full lg:w-4/6 2xl:w-2/4 text-center flex flex-col-reverse lg:flex-row place-content-center align-middle space-x-0 lg:space-x-16 items-center lg:items-end'>
                    <div className='w-3/4'>
                        <LazyLoadImage src={flatAE} placeholderSrc={flatAELQ} className='w-full mb-2 lg:mb-6' alt="artwork collection" />
                        <p className='text-mobile leading-6 lg:leading-7 md:text-average font-semibold text-offWhite'>Artwork #1833</p>
                    </div>
                    <motion.div className='relative w-2/3 lg:w-1/3 mt-24 h-screen lg:h-auto hidden lg:block'>
                        { shouldAnimate && <motion.div variants={containerVariants} initial="initial" animate="animate" exit="exit">
                        <motion.img key="background" variants={deconstructedBackground} initial="initial" animate="animate" exit="exit"src={artworkLayer0} className='w-full' />
                            <motion.img custom={1} key="layer-1" variants={deconstructedAlpha} src={artworkLayer1} className='w-full absolute top-0' />
                            <motion.img custom={2} key="layer-2" variants={deconstructedAlpha} src={artworkLayer2} className='w-full absolute top-0' />
                            <motion.img custom={3} key="layer-3" variants={deconstructedAlpha} src={artworkLayer3} className='w-full absolute top-0' />
                            <motion.img custom={4} key="layer-4" variants={deconstructedAlpha} src={artworkLayer4} className='w-full absolute top-0' />
                            <motion.img custom={5} key="layer-5" variants={deconstructedAlpha} src={artworkLayer5} className='w-full absolute top-0' />
                            <motion.img custom={6} key="layer-6" variants={deconstructedAlpha} src={artworkLayer6} className='w-full absolute top-0' />
                            <motion.img custom={7} key="layer-7" variants={deconstructedAlpha} src={artworkLayer7} className='w-full absolute top-0' />
                        </motion.div>}   
                        <p className='text-mobile leading-6 lg:leading-7 md:text-average mt-2 font-semibold text-offWhite'  ref={ref} >Asset assebly</p>
                    </motion.div>
                </div>
            </div>

            <div className='flex flex-col w-full text-center px-4 lg:px-8 bg-almostBlack pt-24 pb-10 lg:space-y-0 '>
                <div className='w-full lg:w-4/6 2xl:w-2/4 overflow-hidden mx-auto'>

                    {/* Alpha Academy */}
                    <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                        To achieve this, I built a React & Express-based website serving as the asset management system and artwork generator. Assets were uploaded via a detailed creation page to assign rarity, categorise, apply custom computer-generated effects, and establish unique rulesets for each asset. This ensured a natural appearance when paired semi-randomly with other character components.
                    </p>
                    <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                        I enabled the generation and management of test versions of the collection to assess asset distribution, rarity, output quality, and variety. A full CRUD implementation allowed for swift browsing, editing, and deletion of assets through an intuitive interface.
                    </p>
                    <VideoComponent source={genicronVideo} autoplay={false} loop={true} className="pb-10" />
                    <p className='w-full 2xl:w-3/4 mx-auto para text-mobile leading-6 lg:leading-7 lg:text-large'>
                        Our efforts culminated in artwork collections that is widely cherished, and shared, eliciting surprise when people discovered the collection's generative origin. We succeeded in nurturing a community vested more in creativity and art than the typical money-focused side often associated with NFTs.
                    </p>
                </div>

                <motion.div initial={{opacity: 0, y: 30}} whileInView={{opacity: 1, y: 0, transition: {delay: 0.5}}} viewport={{once: true, margin: "-100px 0px 0px 0px"}} key="upnext"
                className='w-3/4 lg:w-1/4 2xl:w-1/6 mx-auto text-center snap-center hover:cursor-pointer group flex flex-col lg:place-content-between py-20'>
                    <h3 className='mt-10 mb-4'>View next 👇</h3>
                    <Link to="/alpha-website" title="View the project" className=''>
                        <LazyLoadImage src={alphaWebsiteTile} placeholderSrc={alphaWebsiteTileLQ} className='w-full' alt="artwork collection" />
                        <div className='flex flex-col'>
                            <h3 className='mt-10 mb-4'>Alpha Academy Website</h3>
                            <p className='text-mobile'>
                            Our product site introduced visitors to the Alpha Academy universe, allowed them to buy out artworks, our limited edition drops (via custom ecomm store) as well as providing additional member-only benefits, accessible via Web3 integration.
                            </p>
                            <div className='btn-secondary mt-8 transition-colors group-hover:bg-accent mx-auto' title="View the project">
                                View Project
                            </div>
                        </div>
                    </Link>
                </motion.div>
            </div>

        </motion.div >
    </>);
}